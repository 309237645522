import UrlBuilderInterface from "./interfaces/url-builder-interface";
import { injectable } from "inversify";
import Utils from "@/vue/infrastructure/utils/helpers";

@injectable()
class ApiUrlRequestBuilder implements UrlBuilderInterface {
  private static readonly BASE_API_PATH: string = "api";

  public getEndPoint(endpoint: string): string {
    endpoint = endpoint ? `/${endpoint}` : "";
    return `${Utils.getContextPath(true)}/${ApiUrlRequestBuilder.BASE_API_PATH}/${Utils.getApiPath()}${endpoint}`;
  }

  public getOuthTokenPath(): string {
    return `${Utils.getContextPath(true)}/oauth/token`;
  }

  public getHeaderForBasicAuthorization(): any {
    return {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic ${window.btoa(Utils.getAuthorizationData())}`,
      },
    };
  }

  public getJsonHeadersOption(): any {
    return { headers: { "Content-Type": "application/json" } };
  }

  public getIpinfoHeadersOption(): any {
    return { headers: { Authorization: "Bearer ipinfo.io" } };
  }
}

export { ApiUrlRequestBuilder };
