import { injectable } from "inversify";
import { ApiInterface } from "./interfaces/api-interface";
import Http from "axios";
import { ApiUrlRequestBuilder } from "@/vue/infrastructure/api/api-url-request-builder";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfPaging from "@/vue/domain/paging/df-paging";
import * as qs from "qs";

@injectable()
class Api implements ApiInterface {
  private apiUrlBuilder: ApiUrlRequestBuilder = null;

  constructor() {
    this.apiUrlBuilder = new ApiUrlRequestBuilder();
    this.setDefaultHeaders();
  }

  public setDefaultHeaders() {
    const barearAccessToken: string = Utils.localStorageGetItem(Utils.BEARER_REFRESH_TOKEN_KEY);
    Http.defaults.headers.common = {};

    if (barearAccessToken != null) {
      Http.defaults.headers.common = {
        Authorization: `Bearer ${barearAccessToken}`,
      };
    }
  }

  public downloadFile(params: any): any {
    return new Promise((resolve, reject) => {
      Http.get(`${Utils.getContextPath(true)}/api/files/${params.fileUniqueId}/${params.fileName}?download=true`, { responseType: "blob" })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public login(formData: FormData): any {
    return new Promise((resolve, reject) => {
      Http.post(this.apiUrlBuilder.getOuthTokenPath(), formData, this.apiUrlBuilder.getHeaderForBasicAuthorization())
        .then((response) => {
          Http.defaults.headers.common = {
            Authorization: `Bearer ${response.data.access_token}`,
          };
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getStore(params: any): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`stores/${params.storeAlias}`), {})
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getStores(params: any): any {
    const latitude: number = params.geolocation ? params.geolocation.latitude : undefined;
    const longitude: number = params.geolocation ? params.geolocation.longitude : undefined;
    const filterQuery: string = params.searchQuery ? `&nameLike=${params.searchQuery}&cityLike=${params.searchQuery}` : "";
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`stores?page=${params.paging.number}${filterQuery}`), {
        params: { latitude: latitude, longitude: longitude },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getBrand(): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(""))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getIp(): any {
    return new Promise((resolve, reject) => {
      Http.get("https://ipinfo.io/json", this.apiUrlBuilder.getIpinfoHeadersOption())
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getPromotions(storeAlias: string): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`stores/${storeAlias}/promotions`))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getProducts(promotionAlias: string, storeAlias: string, paging: DfPaging, query: string, contentAliases: Array<string>, customFilter: Array<string>): any {
    let queryParams: Array<string> = [`size=18`];
    queryParams = paging ? [...queryParams, `page=${paging.number}`] : queryParams;
    queryParams = contentAliases.length > 0 ? [...queryParams, ...contentAliases] : queryParams;
    queryParams = query ? [...queryParams, query] : queryParams;
    queryParams = customFilter ? [...queryParams, ...customFilter] : queryParams;
    const queryString = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`promotions/${promotionAlias}/stores/${storeAlias}/products${encodeURI(queryString)}`))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getBrandContents(typeCode: Array<string>): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`contents`), {
        params: {
          typeCode: typeCode || null,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
          });
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getStoreContents(storeAlias: string, typeCode: Array<string>): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`stores/${storeAlias}/contents`), {
        params: {
          typeCode: typeCode || null,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
          });
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getStoreOpenings(storeAlias: string, daysInterval: number): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`stores/${storeAlias}/openings`), {
        params: { daysInterval: daysInterval },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getStoreClosures(storeAlias: string, daysInterval: number): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`stores/${storeAlias}/closings`), {
        params: { daysInterval: daysInterval },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getPromotionContents(promotionAlias: string, storeAlias: string, typeCode: Array<string>): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`stores/${storeAlias}/promotions/${promotionAlias}/contents`), {
        params: {
          typeCode: typeCode || null,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
          });
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getProduct(promotionAlias: string, storeAlias: string, productAlias: string): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`promotions/${promotionAlias}/stores/${storeAlias}/products/${productAlias}`))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getProductByCode(promotionAlias: string, storeAlias: string, productCode: string): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`promotions/${promotionAlias}/stores/${storeAlias}/products-by-code/COD-${productCode}`))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getProductByExternalData(params: any): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`promotions/${params.promotionCode}/stores/${params.storeAlias}/sku/COD-${params.productCode}`))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getProductByLink(params: any): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`promotions/${params.promotionCode}/products/${params.productCode}?storeAlias=${params.storeAlias}`))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getProductsList(promotionAlias: string, storeAlias: string, productsUniqueId: Array<string>): any {
    return new Promise((resolve, reject) => {
      Http.post(
        this.apiUrlBuilder.getEndPoint(`promotions/${promotionAlias}/stores/${storeAlias}/products-list`),
        JSON.stringify({
          uniqueIds: productsUniqueId,
        }),
        this.apiUrlBuilder.getJsonHeadersOption()
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getPromotionGroups(promotionAlias: string): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`promotions/${promotionAlias}/groups`))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public sendFormMail(params: any): any {
    return new Promise((resolve, reject) => {
      Http.post(this.apiUrlBuilder.getEndPoint(`formMail/${params.formMailAlias}`), params.formData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public subscribeNewsletter(params: any): any {
    return new Promise((resolve, reject) => {
      Http.post(this.apiUrlBuilder.getEndPoint(`sendy/manage`), JSON.stringify(params), this.apiUrlBuilder.getJsonHeadersOption())
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public unsubscribeNewsletter(params: any): any {
    return new Promise((resolve, reject) => {
      Http.post(this.apiUrlBuilder.getEndPoint(`sendy/manage`), JSON.stringify(params), this.apiUrlBuilder.getJsonHeadersOption())
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export { Api };
