import { GetterTree } from "vuex";
import StoreStateInterface from "./store-state-interface";
import DfPromotion from "@/vue/domain/promotion/df-promotion";

const storeGetters: GetterTree<StoreStateInterface, any> = {
  stores: (state) => {
    return state.stores;
  },

  brand: (state) => {
    return state.brand;
  },

  promotions: (state) => {
    return state.promotions.filter(
      (promotion: DfPromotion) => !promotion.hidden
    );
  },

  allPromotions: (state) => {
    return state.promotions;
  },

  currentStore: (state) => {
    return state.currentStore;
  },

  currentPromotion: (state) => {
    return state.currentPromotion;
  },

  products: (state) => {
    return state.products;
  },

  productsPaging: (state) => {
    return state.productsPaging;
  },

  storesPaging: (state) => {
    return state.storesPaging;
  },

  shoppingList: (state) => {
    return state.shoppingList;
  },

  activeTags: (state) => {
    return state.activeTags;
  },

  customFiltersQuery: (state) => {
    return state.customFiltersQuery;
  },

  activeCategory: (state) => {
    return state.activeCategory;
  },

  promotionContents: (state) => {
    return state.promotionContents;
  },

  storeContents: (state) => {
    return state.storeContents;
  },

  brandContents: (state) => {
    return state.brandContents;
  },

  promotionGroups: (state) => {
    return state.promotionGroups;
  },

  cachedProducts: (state) => {
    return state.cachedProducts;
  },

  storeOpenings: (state) => {
    return state.storeOpenings;
  },

  storeClosures: (state) => {
    return state.storeClosures;
  },

  productsQueryValue: (state) => {
    return state.productsQueryValue;
  },

  storedPushesList: (state) => {
    return state.storedPushesList;
  },

  scrollbarHidden: (state) => {
    return state.scrollbarHidden;
  },

  initialRoute: (state) => {
    return state.initialRoute;
  },

  history: (state) => {
    return state.history;
  },

  historyIndex: (state) => {
    return state.historyIndex;
  },

  clientNotification: (state) => {
    return state.clientNotification;
  },

  geolocationEnabled: (state) => {
    return state.geolocationEnabled;
  },

  promotionsLoaded: (state) => {
    return state.promotionsLoaded;
  },

  flyerSwitchActive: (state) => {
    return state.flyerSwitchActive;
  },

  productsSwitchActive: (state) => {
    return state.productsSwitchActive;
  },

  promotionsPopupsWhatched: (state) => {
    return state.promotionsPopupsWhatched;
  },
};
export default storeGetters;
