import { ActionTree } from "vuex";
import StoreStateInterface from "./store-state-interface";
import DfStore from "@/vue/domain/store/df-store";
import DfBrand from "@/vue/domain/brand/df-brand";
import DfPromotion from "@/vue/domain/promotion/df-promotion";
import DfProduct from "@/vue/domain/product/df-product";
import DfPaging from "@/vue/domain/paging/df-paging";
import DfCategory from "@/vue/domain/category/df-category";
import DfContent from "@/vue/domain/content/df-content";
import DfShoppingProduct from "@/vue/domain/shopping-product/df-shopping-product";
import DfGroup from "@/vue/domain/group/df-group";
import DfDateRange from "@/vue/domain/store/df-date-range";

const storeActions: ActionTree<StoreStateInterface, any> = {
  setStores: ({ commit }, stores: Array<DfStore>) => {
    return commit("setStoreMutation", stores);
  },

  setBrand: ({ commit }, brand: DfBrand) => {
    return commit("setBrandMutation", brand);
  },

  setPromotions: ({ commit }, promotions: Array<DfPromotion>) => {
    return commit("setPromotionsMutation", promotions);
  },

  setCurrentStore: ({ commit }, currentStore: DfStore) => {
    return commit("setCurrentStoreMutation", currentStore);
  },

  setCurrentPromotion: ({ commit }, currentPromotion: DfPromotion) => {
    return commit("setCurrentPromotionMutation", currentPromotion);
  },

  setProducts: ({ commit }, products: Array<DfProduct>) => {
    return commit("setProductsMutation", products);
  },

  setProductsPaging: ({ commit }, productsPaging: DfPaging) => {
    return commit("setProductsPagingMutation", productsPaging);
  },

  setStoresPaging: ({ commit }, storesPaging: DfPaging) => {
    return commit("setStoresPagingMutation", storesPaging);
  },

  setShoppingList: ({ commit, state }, shoppingList: Array<DfShoppingProduct>) => {
    return commit("setShoppingListMutation", shoppingList);
  },

  setActiveTags: ({ commit, state }, activeTags: Array<DfCategory>) => {
    return commit("setActiveTagsMutation", activeTags);
  },

  setcustomFiltersQuery: ({ commit, state }, customFiltersQuery: Array<string>) => {
    return commit("setcustomFiltersQueryMutation", customFiltersQuery);
  },

  setActiveCategory: ({ commit, state }, activeCategory: DfCategory) => {
    return commit("setActiveCategoryMutation", activeCategory);
  },

  setPromotionContents: ({ commit, state }, promotionContents: Array<DfContent>) => {
    return commit("setPromotionContentsMutation", promotionContents);
  },

  setStoreContents: ({ commit, state }, storeContents: Array<DfContent>) => {
    return commit("setStoreContentsMutation", storeContents);
  },

  setPromotionGroups: ({ commit, state }, promotionGroups: Array<DfGroup>) => {
    return commit("setPromotionGroupsMutation", promotionGroups);
  },

  setCachedProducts: ({ commit, state }, cachedProducts: Array<DfProduct>) => {
    return commit("setCachedProductsMutation", cachedProducts);
  },

  setStoreOpenings: ({ commit, state }, storeOpenings: DfDateRange) => {
    return commit("setStoreOpeningsMutation", storeOpenings);
  },

  setStoreClosures: ({ commit, state }, storeClosures: DfDateRange) => {
    return commit("setStoreClosuresMutation", storeClosures);
  },

  setBrandContents: ({ commit, state }, brandContents: Array<DfContent>) => {
    return commit("setBrandContentsMutation", brandContents);
  },

  setProductsQueryValue: ({ commit, state }, productsQueryValue: string) => {
    return commit("setProductsQueryValueMutation", productsQueryValue);
  },

  setStoredPushesList: ({ commit, state }, storedPushesList: Array<any>) => {
    return commit("setStoredPushesListMutation", storedPushesList);
  },

  setScrollbarHidden: ({ commit, state }, scrollbarHidden: boolean) => {
    return commit("setScrollbarHiddenMutation", scrollbarHidden);
  },

  setInitialRoute: ({ commit, state }, initialRoute: any) => {
    return commit("setInitialRouteMutation", initialRoute);
  },

  setHistory: ({ commit, state }, history: any) => {
    return commit("setHistoryMutation", history);
  },

  setHistoryIndex: ({ commit, state }, historyIndex: any) => {
    return commit("setHistoryIndexMutation", historyIndex);
  },

  setClientNotification: ({ commit }, clientNotification: boolean) => {
    return commit("setClientNotificationMutation", clientNotification);
  },

  setGeolocationEnabled: ({ commit }, geolocationEnabled: boolean) => {
    return commit("setGeolocationEnabledMutation", geolocationEnabled);
  },

  setPromotionsLoaded: ({ commit }, promotionsLoaded: boolean) => {
    return commit("setPromotionsLoadedMutation", promotionsLoaded);
  },

  setFlyerSwitchActive: ({ commit }, flyerSwitchActive: boolean) => {
    return commit("setFlyerSwitchActiveMutation", flyerSwitchActive);
  },

  setProductsSwitchActive: ({ commit }, productsSwitchActive: boolean) => {
    return commit("setProductsSwitchActiveMutation", productsSwitchActive);
  },

  setPromotionsPopupsWhatched: ({ commit }, promotionsPopupsWhatched: Array<string>) => {
    return commit("setPromotionsPopupsWhatchedMutation", promotionsPopupsWhatched);
  },
};

export default storeActions;
