const langIt = {
  "df-application.tags-filter-placeholder": "Altro ...",
  "df-application.products-search-placeholder": "Cosa stai cercando?",
  "df-application.tags-filter": "Filtra per tag",
  "df-application.reset-products-filters": "Reimposta",
  "df-application.close-filters": "Ok",
  "df-application.category-filter.options.all": "Tutte le categorie",
  "df-application.category-filter.placeholder": "Tutte le categorie",

  "df-router-title-page-home": "Home",
  "df-router-title-page-promotions": "Promozioni",
  "df-router-title-page-news": "News",
  "df-router-title-page-recipes": "Ricette",
  "df-router-title-page-notifications": "Notifiche",
  "df-router-title-page-shopping-list": "Lista della spesa",
  "df-router-title-page-store": "Punto vendita",
  "df-router-title-page-store-selection": "Selezione punto vendita",
  "df-router-title-page-store-change": "Cambio punto vendita",
  "df-router-title-page-club-card": "Carta club",
  "df-router-title-page-newsletter": "Newsletter",

  "df-header.application-name": "SMT Digital Flyer",
  "df-header.application-description": "Smart digital flyer",
  "df-header.portal.df-modal.store-selection.title": "Seleziona punto vendita",
  "df-header.portal.df-modal.promotion-selection.title": "Seleziona una promozione",
  "df-header.promotions": "Promozioni",
  "df-header.shopping-list": "Lista",
  "df-header.notifications": "Novità",
  "df-header.news": "News",
  "df-header.selex.store-name": "Stai visualizzando il volantino di: {0}",
  "df-header.selex.mobile.store-name": "Volantino di: {0}",

  "df-promotion-card.date-range": "Dal {0} al {1}",

  "df-card-attachment.show": "Visualizza",
  "df-card-attachment.download": "Scarica",

  "df-card-shopping-list.label": "Lista della spesa",
  "df-card-shopping-list.title": "Lista della spesa {0}",
  "df-card-shopping-list.add-to-list": "Aggiungi alla lista",
  "df-card-shopping-list.empty-list": "La lista della spesa è vuota",
  "df-card-shopping-list.delete.title": "Svuota la lista",
  "df-card-shopping-list.delete.body": "Stai per eliminare i prodotti dalla lista della spesa. Continuare?",

  "df-section-attachments.title": "Allegati",

  "df-section-promotions.title.flyer": "Scopri le promozioni",
  "df-section-promotions.title.catalog": "Scopri i cataloghi",
  "df-section-promotions.title.magazine": "Scopri i nostri magazine",

  "df-section-news.title": "Le news",

  "df-section-recipes.title": "Le ricette",

  "df-section-products.title": "I prodotti",
  "df-section-products.filter.products-counter": "1 prodotto trovato | {count} prodotti trovati",
  "df-section-products.products-counter": "Stai visualizzando <strong>{count}</strong> prodotti di {total}",

  "df-store-selection.search": "Cerca",
  "df-store-selection.empty": "Nessun punto vendita trovato",

  "df-app-navigation.home": "Home",
  "df-app-navigation.promotions": "Promozioni",
  "df-app-navigation.news": "News",
  "df-app-navigation.shopping-list": "Spesa",
  "df-app-navigation.list": "Lista",

  "df-product-feautured-card.discount-range": "Valido dal {0} al {1}",

  "df-product-datails-data.discount-range": "Valido dal {0} al {1}",
  "df-product-datails-data.add-shoppin-list": "Aggiungi alla lista della spesa",
  "df-product-datails-data.added-shoppin-list": "Aggiunto alla lista della spesa",
  "df-product-datails-data.points": "{0} punti",
  "df-product-datails-data.urls.ecommerce": "E-commerce",
  "df-product-datails-data.urls.video": "Video",
  "df-product-datails-data.urls.url": "Informazioni",

  "df-product-datails-related-products.title": "Ti potrebbero piacere",

  "df-product-datails-extra-content.ingredients": "Ingredienti",
  "df-product-datails-extra-content.nutritional-values": "Valori nutrizionali",
  "df-product-datails-extra-content.nutritional-values-calculated": "Valori nutrizionali calcolati",

  "df-page-promotions.title": "Le promozioni",
  "df-page-promotions.empty-list": "Nessuna promozione presente",

  "df-page-promotion.empty-list": "La ricerca non ha prodotto risultati",
  "df-page-promotion.switch-view.button-products": "Lista",
  "df-page-promotion.switch-view.button-flyer": "Volantino PDF",
  "df-page-promotion.switch-view.label-products": "Lista prodotti",
  "df-page-promotion.switch-view.label-flyer": "Volantino PDF",
  "df-page-promotion.button.download-flyer": "Scarica PDF",
  "df-page-promotion.button.fullscreen-flyer": "Schermo intero",
  "df-page-promotion.button.download-flyer-banners": "Scarica il volantino in PDF",
  "df-page-promotion.button.download-flyer.close": "Chiudi",

  "df-page-news.title": "Le news",
  "df-page-news.empty-list": "Nessuna notizia presente",

  "df-page-recipes.title": "Le ricette",
  "df-page-recipes.empty-list": "Nessuna ricetta presente",

  "df-page-special.products-counter": "<strong>{count}</strong> prodotti",

  "df-section-links.club-card": "Carta Club",
  "df-section-links.cosi-comodo": "Cosìcomodo",

  "df-page-club-card.change-club-card-number": "Cambia il numero della carta",
  "df-page-club-card.save-club-card-number": "Salva il numero della carta",
  "df-page-club-card.restore-club-card-number": "Annulla",
  "df-page-club-card.scan-club-card-number": "scansiona carta",
  "df-page-club-card.insert-club-card-number": "Inserisci la tua Carta Club",
  "df-page-club-card.club-card-number-error": "Numero di carta non valido",
  "df-page-club-card.visit-club-card-site": "Visita il sito di Carta Club",

  "df-store-card.store-selection": "Scegli il supermercato",
  "df-store-card.customer-care-label": "Servizio clienti",
  "df-store-card.special-opening": "Aperture straordinarie",
  "df-store-card.special-closure": "Chiusure straordinarie",
  "df-store-card.special-date-single-day": "{0}",
  "df-store-card.special-date-multiple-day": "Dal {0} al {1}",
  "df-store-card.hours-range": "({0}-{1})",
  "df-store-card.additional-hours-range": "({0}-{1} / {2}-{3})",

  "df-page-store.change-store": "Cambia punto vendita",

  "df-page-notifications-list.empty-list": "Non ci sono notifiche",

  "df-card-product.promo-title": "Sottocosto",
  "df-card-product.discount.upu": "1+1 Gratis",
  "df-card-product.free-points": "<span class='df-value'>{0}</span> punti fidelity",
  "df-card-product.cash-points": "o <span class='df-value'>{0}</span> punti e <span class='df-value'>{1}</span>",
  "df-card-product.discount-range": "Valido dal {0} al {1}",
  "df-card-product.discount.STC": "SOTTOCOSTO",
  "df-card-product.discount.SOT": "SOTTOCOSTO",
  "df-card-product.discount.DSV": "SOTTOPREZZO",
  "df-card-product.discount.SPF": "SOTTOPREZZO",
  "df-card-product.discount.TZV": "CON CARTA EURONICS",
  "df-card-product.discount.STC.pieces": "{0} pz disponibili",
  "df-card-product.discount.SOT.pieces": "pezzi disponibili: <strong>{0}</strong>",
  "df-card-product.buy-in-shop": "Acquista in negozio",
  "df-card-product.reserve": "Prenota e ritira",
  "df-card-product.buy-online": "Compra online",

  "df-campaign-card.button-label": "Scopri di più",

  "df-page-recipe.method": "Procedimento",
  "df-page-recipe.ingrediants": "Ingredienti",
  "df-page-recipe.difficulty": "Difficoltà",
  "df-page-recipe.preparation-time": "Durata",
  "df-page-recipe.cooking-time": "Cottura",
  "df-page-recipe.minuts": "{0} min ",
  "df-page-recipe.doses": "Dosi",
  "df-page-recipe.servings": "{0} persone ",

  "df-page-recipe-products.title": "Acquista i prodotti per la ricetta",

  "df-newsletter-page.title": "Newsletter",
  "df-newsletter-page.form.email.label": "Indirizzo email",
  "df-newsletter-page.form.email.placeholder": "Inserisci il tuo indirizzo email",
  "df-newsletter-page.form.name.label": "Nome",
  "df-newsletter-page.form.lastname.label": "Cognome",
  "df-newsletter-page.form.name.placeholder": "Inserisci il tuo nome",
  "df-newsletter-page.form.lastname.placeholder": "Inserisci il tuo cognome",
  "df-newsletter-page.form.newsletter-terms-condition-acceptance.label": "Ho letto e accetto ",
  "df-newsletter-page.form.newsletter-terms-condition-acceptance.link": "l'informativa sulla privacy",
  "df-newsletter-page.form.errors.required": "Il campo è obbligatorio",
  "df-newsletter-page.form.subscribe": "Iscriviti",
  "df-newsletter-page.form.unsubscribe": "Annulla iscrizione",
  "df-newsletter-page.swal.privacy.title": "Informativa sulla privacy",
  "df-newsletter-page.swal.privacy.confirm": "Accetta",
  "df-newsletter-page.swal.privacy.cancel": "Annulla",
  "df-newsletter-page.subscribe.error-title": "Si è verificato un errore:",
  "df-newsletter-page.unsubscribe.error-title": "Si è verificato un errore:",

  "df-newsletter-page.subscribe.successful": "Iscrizione conclusa con successo!",
  "df-newsletter-page.unsubscribe.successful": "Cancellazione conclusa con successo!",
  "df-newsletter-page.home": "Home",

  "df-page-form-mail.submitted.successful": "fatto",
  "df-page-form-mail.home": "Home",
  "df-page-form-mail.form.submit": "Invia",

  "enum-df-day-of-week.monday": "Lunedì",
  "enum-df-day-of-week.tuesday": "Martedì",
  "enum-df-day-of-week.wednesday": "Mercoledì",
  "enum-df-day-of-week.thursday": "Giovedì",
  "enum-df-day-of-week.friday": "Venerdì",
  "enum-df-day-of-week.saturday": "Sabato",
  "enum-df-day-of-week.sunday": "Domenica",

  "enum-df-newsletter-subscribe-status.MISSING_FIELDS": "Verificare i campi obbligatori e riprovare",
  "enum-df-newsletter-subscribe-status.NO_API_KEY": "Chiave API assente",
  "enum-df-newsletter-subscribe-status.INVALID_API_KEY": "Chiave API non valida",
  "enum-df-newsletter-subscribe-status.INVALID_EMAIL_ADDRESS": "Indirizzo e-mail non valido",
  "enum-df-newsletter-subscribe-status.ALREADY_SUBSCRIBED": "Sei già iscritto alla newsletter",
  "enum-df-newsletter-subscribe-status.BOUNCED_EMAIL_ADDRESS": "Il tuo indirizzo e-mail è stato bloccato",
  "enum-df-newsletter-subscribe-status.SUPPRESSED_EMAIL_ADDRESS": "Il tuo indirizzo e-mail è stato bloccato",
  "enum-df-newsletter-subscribe-status.INVALID_LIST_ID": "Newsletter non trovata",
  "enum-df-newsletter-subscribe-status.TRUE": "Iscrizione conclusa con successo",
  "enum-df-newsletter-subscribe-status.FALSE": "Si prega di riprovare più tardi",

  "enum-df-newsletter-unsubscribe-status.MISSING_FIELDS": "Verificare i campi obbligatori e riprovare",
  "enum-df-newsletter-unsubscribe-status.INVALID_EMAIL_ADDRESS": "Indirizzo e-mail non valido",
  "enum-df-newsletter-unsubscribe-status.EMAIL_ADDRESS_NOT_FOUND": "Indirizzo e-mail non trovato",
  "enum-df-newsletter-unsubscribe-status.TRUE": "Cancellazione  conclusa con successo",
  "enum-df-newsletter-unsubscribe-status.FALSE": "Si prega di riprovare più tardi",

  "df-measure-unit.kg.label": "al KG",
  "df-measure-unit.hg.label": "all'etto",
  "df-measure-unit.l.label": "al litro",
  "df-special-price": "Prezzo<br />speciale",
};

export default langIt;
